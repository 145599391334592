import styles from "./account.module.css"

import { MdAccountCircle } from "react-icons/md";
import { HiUpload } from "react-icons/hi";
import { MdDeleteOutline } from "react-icons/md";
import { AiOutlineWarning } from "react-icons/ai";
import { FaUser } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../../../Service/AuthContext";
import axios from 'axios';
import { MdEdit } from "react-icons/md";
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useGlobalVariablesContext } from "../../../../Service/GlobalVariables";
import InputAdornment from '@mui/material/InputAdornment';
import { TextField } from '@mui/material';
import ErrorSnackbar from "../../../../Components/ErrorSnackbar/ErrorSnackbar";
import SuccessSnackbar from "../../../../Components/SuccessSnackbar/SuccessSnackbar";
import ChangePassword from "./accounts_components/ChangePassword/ChangePassword";
import Avatar from '@mui/material/Avatar';



const CssTextField = styled(TextField)({
    // '& label.Mui-focused': {
    //   color: '#f37440',
    // },x
    // '& .MuiInput-underline:after': {
    //   borderBottomColor: '#f37440',
    // },


    // "& fieldset": { border: 'none' },

    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#edededde',
            width: "none"
        },

        "& input::placeholder": {
            fontSize: "13px"
        },
        width: '370px',
        borderRadius: "12px"

        // '&:hover fieldset': {
        //   borderColor: 'yellow',
        // },
        // '&.Mui-focused fieldset': {
        //   borderColor: '#f37440',
        // },
    },
    "& .MuiInputBase-root.Mui-disabled": {
        backgroundColor: "#3e638c0d",
        '& fieldset': {
            border: 'none !important',
        },
    },



});


export const Account = () => {


    const styleConfirmModal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        height: 200,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [isModalOpen, setModalOpen] = useState(false);
    const modalRef = useRef();
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const authToken = "ComcatChatbotApi123$"
    const [changePass, setChangePass] = useState()
    const formRef = useRef(null)
    const [region, setRegion] = useState("")
    const [language, setLanguage] = useState("")
    const { widgetID, userAttributes, setUserAttributes } = useAuth();
    const [name, setName] = useState(userAttributes.name || "");
    const [email, setEmail] = useState(userAttributes.email || "");
    const [isEditing, setIsEditing] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [changesMade, setChangesMade] = useState(false);

    const [openConfirm, setOpenConfirm] = useState(false);
    const [isSnackbarOpen, setIsSnackbarOpen] = useState({ isOpen: false, message: "" });
    const [isErrorSnackbarOpen, setIsErrorSnackbarOpen] = useState({ isOpen: false, message: "" });
    const [changesInProgress, setChangesInProgress] = useState(false);
    const [savePasswordChangesInProgress, setSavePasswordChangesInProgress] = useState(false);

    const { setGlobalProfilePicture, profilePicture } = useGlobalVariablesContext();
    const [previewURL, setPreviewURL] = useState(profilePicture);

    const [saveChangedPassword, setSaveChangedPassword] = useState({
        oldPassword: "", newPassword: "", confirmPassword: ""
    })

    const onClose = () => {
        setModalOpen(!isModalOpen)
    }

    const handleOpenConfirm = () => {
        setOpenConfirm(true);
    };

    const handleModalClose = () => {
        setOpenConfirm(false);
    };


    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsSnackbarOpen({ isOpen: false, message: "" });
    };

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsErrorSnackbarOpen({ isOpen: false, message: "" });
    };

    const handleErrorSnackbar = (isOpen, message) => {
        setIsErrorSnackbarOpen({ isOpen, message })
    }

    const handleIconContainerClick = () => {
        // Trigger file input click
        const fileInput = document.getElementById('fileInput');
        fileInput.click();
    };

    const handleFileInputChange = (e) => {
        if (e.target.files.length !== 0) {
            const file = e.target.files[0];
            const reader = new FileReader();

            reader.onload = (event) => {
                const image = new Image();
                image.src = event.target.result;

                image.onload = () => {
                    const canvas = document.createElement('canvas');
                    const MAX_WIDTH = 800; // Set your desired maximum width
                    const MAX_HEIGHT = 600; // Set your desired maximum height
                    let width = image.width;
                    let height = image.height;

                    if (width > height) {
                        if (width > MAX_WIDTH) {
                            height *= MAX_WIDTH / width;
                            width = MAX_WIDTH;
                        }
                    } else {
                        if (height > MAX_HEIGHT) {
                            width *= MAX_HEIGHT / height;
                            height = MAX_HEIGHT;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(image, 0, 0, width, height);

                    canvas.toBlob((blob) => {
                        const compressedFile = new File([blob], file.name, {
                            type: file.type,
                            lastModified: Date.now(),
                        });

                        setSelectedFile(compressedFile);
                    }, file.type, 0.7);

                    setPreviewURL(URL.createObjectURL(file));
                    URL.revokeObjectURL(URL.createObjectURL(file));

                    // Clear the file input value to allow re-selecting the same file
                    e.target.value = null;
                };
            };

            reader.readAsDataURL(file);
        }
        // setChangesMade(true);
    };

    const handleSnackbarOpen = (isOpen, message) => {
        setIsSnackbarOpen({ isOpen, message })
    }

    const CallProfilePictureAPI = async (imageBase64) => {

        const requestData = {
            file: imageBase64,
            username: userAttributes.email
        };


        try {
            // Make the Axios POST request
            const response = await axios.post('https://stampa.zirvik.com/uploadFile', requestData, {
                headers: {
                    Authorization: authToken 
                  }
            });
            console.log('Success:', response.data);
            setChangesInProgress(false)
            setIsEditing(!isEditing);
            setChangesMade(false)
            handleSnackbarOpen(true, "Your changes have been successfully made")
            if (imageBase64 === null) {
                setGlobalProfilePicture(null)
            } else {
                const ImageUrl = `data:image/png;base64,${imageBase64}`
                setGlobalProfilePicture(ImageUrl)
            }

        } catch (error) {
            // Handle errors
            setChangesInProgress(false)
            // setIsErrorSnackbarOpen(true)
            handleErrorSnackbar(true, "No changes have been made; please try again later.")
            console.error('Error:', error.response ? error.response.data : error.message);
        }
    }


    const handleEditClick = () => {
        setIsEditing(!isEditing);
    };

    const handleCancelClick = () => {
        setIsEditing(!isEditing);
        setName((userAttributes.name || ""))
        setSelectedFile(null)

        setChangesMade(false)
        setPreviewURL(profilePicture)
    };

    const handlePassChange = (e) => {
        setSaveChangedPassword((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }
    const handleChangePassClick = () => {
        setChangePass(!changePass)
        setTimeout(() => {
            // Check if the formRef is assigned
            if (formRef.current) {
                // Scroll to the form element
                formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }, 0);
    }

    const handleSaveButton = async () => {

        handleOpenConfirm();
    };

    const handleImageUpload = () => {
        console.log("Selected File : ", selectedFile)


        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = () => {
                const dataUrl = reader.result;
                const imageBase64 = dataUrl.split(",")[1];
                CallProfilePictureAPI(imageBase64)
            };
            reader.readAsDataURL(selectedFile);
            setModalOpen(!isModalOpen)
        }
        else {
            const imageBase64 = null
            CallProfilePictureAPI(imageBase64)
            setModalOpen(!isModalOpen)

        }

    }

    const handleConfirmChanges = async () => {
        setChangesInProgress(true)
        setOpenConfirm(false);
        if (userAttributes.name !== name) {
            const requestData = {
                username: userAttributes.email,
                newName: name
            };
            try {
                // Make the Axios POST request
                const response = await axios.post('https://stampa.zirvik.com/updateUserName', requestData, {
                    headers: {
                      Authorization: authToken // Include the authToken in the Authorization header
                    }
                  });
                console.log('Success:', response.data);
                const updatedUserAttributes = { ...userAttributes, name: name };

                console.log("updatedUserAttributes=====>", updatedUserAttributes)
                setUserAttributes(updatedUserAttributes);
                setChangesInProgress(false)
                setIsEditing(!isEditing);
                setChangesMade(false)
                handleSnackbarOpen(true, "Your changes have been successfully saved")


            } catch (error) {
                // Handle errors
                setChangesInProgress(false)
                setIsErrorSnackbarOpen(true)
                handleErrorSnackbar(true, "Error in changing name.")
                console.error('Error in changing name :', error.response ? error.response.data : error.message);
            }
        }

        // if (selectedFile) {
        //     const reader = new FileReader();
        //     reader.onload = () => {
        //         const dataUrl = reader.result;
        //         const imageBase64 = dataUrl.split(",")[1];
        //         CallProfilePictureAPI(imageBase64)
        //     };
        //     reader.readAsDataURL(selectedFile);
        // }


    };

    const savePassword = () => {
        console.log(saveChangedPassword)
        setChangePass(!changePass)
    }

    const handleImageUploadModal = () => {
        if (isEditing) {
            setModalOpen(!isModalOpen)
        }
    }

    const handleRemoveImage = () => {
        console.log("Remove File")
        setPreviewURL(null)
        setSelectedFile(null)
        setChangesMade(!changesMade)
    }

    const handleModalCloseBtnClick = () => {
        handleCancelClick()
        setModalOpen(!isModalOpen)
    }

    const operator_modal_form = () => {

        return (
            <div ref={modalRef} style={{ display: isModalOpen ? "block" : "none", textAlign: 'center' }} className={styles.modalStyles}>
                <h1 style={{ textAlign: 'left', fontSize: '30px' }}>Change Your Profile Picture</h1>
                <div className={styles.modal_content_container}>
                    <div className={styles.action_btns_container}>
                        <div onClick={handleIconContainerClick} className={styles.action_btn}>
                            <span className={styles.icon_container}>
                                <HiUpload style={{ width: "30px", height: '30px' }} />
                            </span>
                            <p style={{ fontSize: "20px", margin: "0" }}>Upload picture</p>
                        </div>
                        <div className={styles.remove_image_btn_container} onClick={handleRemoveImage}>
                            <span className={styles.icon_container}>
                                <MdDeleteOutline style={{ width: "30px", height: '30px' }} />
                            </span>
                            <p style={{ fontSize: "20px", margin: "0" }}>Remove picture</p>
                        </div>
                    </div>

                    <div className={styles.upload_icon_container_modal} style={{ backgroundColor: !isEditing ? "#f8faff" : '', padding: previewURL ? "0px" : '10px', borderRadius: !previewURL ? "100px" : "", border: !previewURL ? "1px solid #DBDBDB" : "" }} onClick={handleIconContainerClick}>
                        {previewURL ? (
                            <img src={previewURL} alt="Preview" className={styles.upload_image_modal} />
                        ) : (
                            <FaUser className={styles.FaUser_image_modal} />
                        )}
                    </div>
                    <input
                        type="file"
                        id="fileInput"
                        style={{ display: 'none' }}
                        onChange={handleFileInputChange}
                        accept="image/jpeg, image/jpg, image/png"
                        disabled={!isEditing}
                    />

                </div>
                <div className={styles.close_save_btns_container}>
                    <button onClick={handleModalCloseBtnClick} className={styles.close_btn}>Close</button>
                    <button className={styles.save_btn} onClick={handleImageUpload}>Save</button>
                </div>

            </div>
        )
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                if (isModalOpen) handleModalCloseBtnClick();
            }
        };

        // Add event listener to handle clicks outside of modal
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);


    return (
        <div className={styles.installation_container}>
            <div className={styles.top_bar}>
                <div className={styles.personal_details_container}>
                    <MdAccountCircle className={styles.account_icon} />
                    <p className={styles.p_text}>Personal details</p>
                </div>
                <div className={styles.button_container}>
                    {changesMade && (
                        <button onClick={handleSaveButton} className={styles.save_button}>
                            Save
                        </button>
                    )}
                    {isEditing && (
                        <button onClick={handleCancelClick} className={styles.cancel_button}> Cancel</button>
                    )}
                    {!isEditing && (
                        <button onClick={handleEditClick} className={styles.edit_button}><MdEdit style={{ fontSize: "16px" }} /> Edit</button>
                    )}
                </div>

            </div>
            <div className={styles.installation_guide_container}>
                {/* right side */}
                <div className={styles.right_side}>
                    <div className={styles.top_section}>

                        <div className={styles.input_container}>
                            <label htmlFor="">Name</label>
                            <CssTextField

                                variant="outlined"

                                placeholder="Full Name"
                                value={name}
                                onChange={(e) => {
                                    const newName = e.target.value;
                                    setName(newName);
                                    setChangesMade(newName !== userAttributes.name); // Update changesMade only if the new value is different from the old value
                                }}
                                required
                                disabled={!isEditing}
                                style={{ color: !isEditing ? "#8E8EA0" : '#525252' }}
                                className={styles.custom_input}

                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                                                <path d="M16.6401 22H7.36009C6.34927 21.9633 5.40766 21.477 4.79244 20.6742C4.17722 19.8713 3.95266 18.8356 4.18009 17.85L4.42009 16.71C4.69613 15.1668 6.02272 14.0327 7.59009 14H16.4101C17.9775 14.0327 19.3041 15.1668 19.5801 16.71L19.8201 17.85C20.0475 18.8356 19.823 19.8713 19.2077 20.6742C18.5925 21.477 17.6509 21.9633 16.6401 22Z" fill="#083A5E" />
                                                <path d="M12.5001 12H11.5001C9.29096 12 7.50009 10.2092 7.50009 8.00001V5.36001C7.49743 4.46807 7.85057 3.61189 8.48127 2.98119C9.11197 2.35049 9.96815 1.99735 10.8601 2.00001H13.1401C14.032 1.99735 14.8882 2.35049 15.5189 2.98119C16.1496 3.61189 16.5028 4.46807 16.5001 5.36001V8.00001C16.5001 9.06088 16.0787 10.0783 15.3285 10.8284C14.5784 11.5786 13.561 12 12.5001 12Z" fill="#083A5E" />
                                            </svg>
                                        </InputAdornment>
                                    ),
                                }}
                            /></div>

                        <div className={styles.input_container}>
                            <label htmlFor="">Email</label>
                            <CssTextField
                                className={styles.custom_input}
                                variant="outlined"
                                onChange={(e) => setEmail(e.target.value)}
                                disabled // Disable the input field
                                // FormHelperTextProps={{ style: { color: '#d32f2f', width: '370px', marginLeft: '0px', } }}
                                placeholder="Email"
                                value={email}
                                required
                                // helperText={errorEmail}
                                // error={!!errorEmail}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M4.99984 3.33331H14.9998C16.8408 3.33331 18.3332 4.8257 18.3332 6.66665V14.1666C18.3332 16.0076 16.8408 17.5 14.9998 17.5H4.99984C3.15889 17.5 1.6665 16.0076 1.6665 14.1666V6.66665C1.6665 4.8257 3.15889 3.33331 4.99984 3.33331ZM11.1665 11.2916L16.4498 7.37498C16.6856 7.20164 16.7377 6.87067 16.5665 6.63331C16.4849 6.51999 16.3609 6.44452 16.2227 6.42411C16.0846 6.40371 15.9441 6.44009 15.8332 6.52498L10.4915 10.4166C10.3578 10.5567 10.1726 10.636 9.979 10.636C9.78537 10.636 9.60017 10.5567 9.4665 10.4166L4.1665 6.52498C4.05615 6.44276 3.91756 6.40797 3.78146 6.4283C3.64536 6.44864 3.523 6.52243 3.4415 6.63331C3.26852 6.86851 3.31681 7.19908 3.54984 7.37498L8.79984 11.25C9.12652 11.5599 9.55792 11.7354 10.0082 11.7416C10.4369 11.7416 10.8501 11.581 11.1665 11.2916Z" fill="#083A5E" />
                                            </svg>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div className={styles.upload_input_container}>
                            <label htmlFor="">Your picture</label>
                            <div className={styles.upload_container}>
                                <div className={styles.upload_icon_container} style={{ backgroundColor: !isEditing ? "#3e638c0d" : '', padding: previewURL ? "0px" : '10px' }} onClick={handleImageUploadModal}>
                                    {previewURL ? (
                                        <img src={previewURL} alt="Preview" className={styles.upload_image} />
                                    ) : (
                                        <Avatar sx={{ fontSize: "1.5rem", bgcolor: !isEditing? "#c6c6c6": "#223952f2", height: '50px', width: '50px' }}>
                                            {userAttributes.name.charAt(0).toUpperCase()}
                                        </Avatar>
                                    )}
                                </div>

                                {operator_modal_form()}
                                <div style={{ display: isModalOpen ? "block" : "none" }} className={styles.overlayStyles}></div>

                                <p className={styles.upload_text}>Upload Image support JPEG, JPG, PNG, Max file size: 5MB</p>
                            </div>
                        </div>
                        {/* <div className={styles.input_container}>
                            <label htmlFor="">Region</label>

                            <CustomSelect options={options} width="420px" height="60px" onChange={setRegion} borderColor="#ced4da" />
                        </div> */}
                        {/* <div className={styles.input_container}>
                            <label htmlFor="">Language</label>
                            <CustomSelect options={options} width="420px" height="60px" onChange={setLanguage} borderColor="#ced4da" />
                        </div> */}
                    </div>

                    {/* forgot password section */}
                    <div className={styles.top_bar} style={{ marginTop: "10px" }}>
                        <div className={styles.personal_details_container}>
                            <MdAccountCircle className={styles.account_icon} />
                            <p className={styles.p_text}>Password</p>
                        </div>
                        <div>
                            {/* <button>New addition</button> */}
                        </div>
                    </div>
                    <div className={styles.bottom_section}>
                        <div>
                            <p style={{ marginBottom: "10px" }}>Password</p>
                            <span >Two-Factor</span>
                            <br />
                            <span>Authenticatioin (2FA)</span>
                        </div>
                        <div>
                            <p className={styles.change_pass_btn} style={{ color: "#2563EB", marginBottom: "10px", cursor: "pointer" }} onClick={handleChangePassClick}>Change Password</p>
                            <span className={styles.fa_inactive}><AiOutlineWarning className={styles.warning_sign} />2FA is inactive</span>
                            <p style={{ marginBottom: "10px", marginTop: "5px" }} className={styles.security_message}>
                                Protect your catcom account with an additional layer of security. Connect your account to an uthenticator app on your mobile device.
                            </p>
                            <span style={{ color: "#2563EB" }}>Enable 2FA</span>
                        </div>
                    </div>
                    {/* change password */}
                    {changePass &&
                        <ChangePassword
                            formRef={formRef}
                            handlePassChange={handlePassChange}
                            CssTextField={CssTextField}
                            saveChangedPassword={saveChangedPassword}
                            showNewPassword={showNewPassword}
                            setShowNewPassword={setShowNewPassword}
                            setShowConfirmPassword={setShowConfirmPassword}
                            showConfirmPassword={showConfirmPassword}
                            userAttributes={userAttributes}
                            handleErrorSnackbar={handleErrorSnackbar}
                            setSavePasswordChangesInProgress={setSavePasswordChangesInProgress}
                            handleSnackbarOpen={handleSnackbarOpen}
                            setChangePass={setChangePass}
                            changePass={changePass}
                            setIsErrorSnackbarOpen={setIsErrorSnackbarOpen}
                            isErrorSnackbarOpen={isErrorSnackbarOpen}
                            setSaveChangedPassword={setSaveChangedPassword}
                        />

                    }
                </div>
            </div>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openConfirm}
                onClose={handleModalClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={openConfirm}>
                    <Box sx={styleConfirmModal}>
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            Alert
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                            Are you sure you want to save these changes?
                        </Typography>

                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                            <Button onClick={handleConfirmChanges} variant="contained" color="primary">
                                Save
                            </Button>
                            <Button onClick={handleModalClose} variant="outlined" color="secondary" style={{ marginLeft: '1rem' }}>
                                Exit
                            </Button>
                        </div>
                    </Box>
                </Fade>
            </Modal>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={changesInProgress}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* for save password changes */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={savePasswordChangesInProgress}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <SuccessSnackbar setIsSnackbarOpen={setIsSnackbarOpen} isSnackbarOpen={isSnackbarOpen} handleSnackbarClose={handleSnackbarClose} />

            <ErrorSnackbar setIsErrorSnackbarOpen={setIsErrorSnackbarOpen} isErrorSnackbarOpen={isErrorSnackbarOpen} handleErrorSnackbarClose={handleErrorSnackbarClose} />

        </div>
    )
}
