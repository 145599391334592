import styles from "./notifications.module.css"

import { HiBell } from "react-icons/hi2";
import { styled } from '@mui/material/styles';

import { useRef, useState, useEffect } from "react";
import Alert from '@mui/material/Alert';
import BasicTable from "./BasicTable/BasicTable";
import Switch from '@mui/material/Switch';
import * as api from '../../../../Service/API.js';
import { useSound } from '../../../../Service/SoundContext';
import { useAuth } from '../../../../Service/AuthContext.js';
import Backdrop from '@mui/material/Backdrop';
import SuccessSnackbar from "../../../../Components/SuccessSnackbar/SuccessSnackbar.js";
import { CircularProgress } from "@mui/material";

import ErrorSnackbar from "../../../../Components/ErrorSnackbar/ErrorSnackbar.js";

export const Notifications = ({ audioFilesData }) => {
    const { soundData, handleUpdateSounds } = useSound();
    const { userAttributes } = useAuth();
    const [notificationsSoundData, setNotificationsSoundData] = useState(soundData)
    const [tempNotifificationsData, setTempNotificationsData] = useState(soundData)
    const [successSnackbar, setSuccessSnackbar] = useState({ isOpen: false, message: '' })

    const [errorSnackbar, setErrorSnackbar] = useState({ isOpen: false, message: '' })

    const [changesInProgress, setChangesInProgress] = useState(false);

    const [trackSounds, setTrackSounds] = useState({
        newVisitorLandAudioChange:true,
        newActiveMessageAudioChange:true,
        newInactiveMessageAudioChange:true,
        newChatRequestAudioChange:true
    })

    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));

    useEffect(() => {

        setNotificationsSoundData(soundData)
    }, [soundData]);

    const handleSaveButton = async () => {
        if(!(Object.values(trackSounds).every(sound => sound === true))){
            setErrorSnackbar({isOpen:true, message:"Kindly make sure you have selected sound for all notifications."})
            return
        }
        setChangesInProgress(true)
        const username = userAttributes.email;
        // Assuming notificationsSoundData is updated somehow
        try {
            const response = await api.updateNotificationSounds(username, notificationsSoundData);
            console.log('Sounds updated successfully:', response);
            handleUpdateSounds(notificationsSoundData); // Update soundData in SoundContext
            setChangesInProgress(false)
            setSuccessSnackbar({ isOpen: true, message: "Sounds Changed Successfully..." })
        } catch (error) {
            console.error('Error updating sounds:', error);
            setChangesInProgress(false)
            setErrorSnackbar({ isOpen: true, message: 'Error in changing sounds...' })
        }
    };


    return (
        <div className={styles.installation_container}>
            <div className={styles.top_bar}>
                <div className={styles.personal_details_container}>
                    <HiBell className={styles.account_icon} />
                    <p className={styles.p_text}>Notifications for John</p>
                </div>
                <div>
                    <button className={styles.saveButton} onClick={handleSaveButton}>Save</button>
                </div>
            </div>
            <div className={styles.installation_guide_container}>
                {/* right side */}
                <div className={styles.right_side}>
                    {/* <div className={styles.notification_error_container}>
                        <Alert severity="error" className={styles.notification_alert}>
                            <span className={styles.span_1}>Notifications are disabled.</span>
                            <span className={styles.span_2}> You might miss some incoming chats.</span>
                            <a className={styles.notification_link} href=""> Enable notifications</a>
                        </Alert>
                    </div> */}
                    {/* top table */}
                    <div className={styles.top_section}>
                        {/* table */}
                        <div>
                            <BasicTable audioFilesData={audioFilesData} notificationsSoundData={notificationsSoundData} setNotificationsSoundData={setNotificationsSoundData} trackSounds={trackSounds} setTrackSounds={setTrackSounds} />
                        </div>
                    </div>
                    <div className={styles.bottom_section}>
                        {/* <div className={styles.widget_container}>
                            <p style={{ margin: "0px", fontSize:"15px" }}>Enable widget sounds</p>
                            <IOSSwitch sx={{ m: 1 }} />
                        </div> */}
                        <div>
                            {/* <button>Save</button> */}
                        </div>
                    </div>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={changesInProgress}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>

                    <SuccessSnackbar isSnackbarOpen={successSnackbar} setIsSnackbarOpen={setSuccessSnackbar} />
                    <ErrorSnackbar isErrorSnackbarOpen={errorSnackbar} setIsErrorSnackbarOpen={setErrorSnackbar} />
                </div>
            </div>
        </div>
    )
}