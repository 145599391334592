import InputAdornment from '@mui/material/InputAdornment';
import { BiHide, BiShow } from 'react-icons/bi';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import styles from "./changePassword.module.css"
import { useEffect, useState } from 'react';
import axios from 'axios';


export default function ChangePassword({formRef ,handlePassChange, CssTextField, saveChangedPassword, showNewPassword,setShowNewPassword, 
    setShowConfirmPassword, showConfirmPassword, userAttributes, isErrorSnackbarOpen,handleErrorSnackbar, setSavePasswordChangesInProgress ,
    handleSnackbarOpen ,setChangePass, changePass ,setIsErrorSnackbarOpen, setSaveChangedPassword}) {

    const [lengthCondition, setLengthCondition] = useState(false)
    const [oneNumCondition, setOneNumCondition] = useState(false)
    const [charsCondition, setCharsCondition] = useState(false)
    const [specialCharCondition, setSpecialCharCondition] = useState(false)
    const authToken = "ComcatChatbotApi123$"

    useEffect(()=>{
        if(saveChangedPassword.newPassword.length >= 6){
            setLengthCondition(true)
        }
        else{
            setLengthCondition(false)
        }

        const numCondition = /\d/.test(saveChangedPassword.newPassword)
        setOneNumCondition(numCondition)

        const upperLowerChars = /(?=.*[a-z])(?=.*[A-Z])/.test(saveChangedPassword.newPassword)
        setCharsCondition(upperLowerChars)

        const specialChar = /(?=.*[!@#$%^&*()_+])/.test(saveChangedPassword.newPassword)
        setSpecialCharCondition(specialChar)


    }, [saveChangedPassword])


    const handleSubmit = async () => {
        console.log("email", userAttributes.email)
        console.log("oldPassword", saveChangedPassword.oldPassword)
        console.log("newPassword", saveChangedPassword.confirmPassword)
        if (!saveChangedPassword.oldPassword || !saveChangedPassword.newPassword || !saveChangedPassword.confirmPassword) {
            handleErrorSnackbar(true, "Kindly make sure to provide all fields.")
            return
        }
        if (saveChangedPassword.newPassword !== saveChangedPassword.confirmPassword) {
            handleErrorSnackbar(true, "Kindly make sure new and confirm password are same.")
            return
        }
        if (!lengthCondition || !oneNumCondition || !charsCondition || !specialCharCondition) {
            handleErrorSnackbar(true, "Kindly make sure you have entered a strong password.")
            return
        }
        try {
            setSavePasswordChangesInProgress(true)
            const response = await axios.post('https://stampa.zirvik.com/change-password', {
                username: userAttributes.email,
                oldPassword: saveChangedPassword.oldPassword,
                newPassword: saveChangedPassword.confirmPassword,
            }, {
                headers: {
                    Authorization: authToken,
                },
            });


            console.log(response.data.message);
            handleSnackbarOpen(true, "Password Updated Successfully")
            setChangePass(!changePass)
            setSaveChangedPassword({
                oldPassword: "", newPassword: "", confirmPassword: ""
            })

        } catch (error) {
            console.log("Error response : ", error.response)
            console.error('Error changing password:', error.response ? error.response.data : error.message);
            setIsErrorSnackbarOpen(!isErrorSnackbarOpen)
            handleErrorSnackbar(true, error.response.data.error || "Error in changing password")

        } finally {
            setSavePasswordChangesInProgress(false)

        }
    };

    return (
        <div style={{ display: "flex" }}>
            <div ref={formRef} className={`${styles.top_section}`} style={{ marginTop: "20px" }}>
                <div className={styles.change_pass_text}>Change Password</div>
                <div className={styles.input_container}>
                    <label htmlFor="">Old Password</label>
                    {/* <input placeholder="Old Password" onChange={handlePassChange} value={saveChangedPassword.oldPassword} className={styles.custom_input} type="text" name="oldPassword" id="" /> */}
                    <CssTextField
                        placeholder="Old Password" onChange={handlePassChange} value={saveChangedPassword.oldPassword} className={styles.custom_input} name="oldPassword"
                    />
                </div>
                <div className={styles.input_container}>
                    <label htmlFor="">New Password</label>
                    <CssTextField
                        className={styles.custom_input}
                        type={showNewPassword ? 'text' : 'password'}
                        // placeholder='Enter password'
                        name='newPassword'
                        placeholder="Password"
                        value={saveChangedPassword.newPassword}
                        onChange={handlePassChange}
                        required
                        // FormHelperTextProps={{ style: { color: '#d32f2f', width: '370px', marginLeft: '0px', } }}
                        // helperText={error}
                        // error={!!error}
                        autoComplete='new-password'
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M15.1721 6.52854V3.66854C15.1726 2.92138 14.869 2.20618 14.3313 1.68746C13.7935 1.16873 13.0679 0.891143 12.3212 0.918538H9.31456C8.5679 0.891143 7.84224 1.16873 7.3045 1.68746C6.76675 2.20618 6.46322 2.92138 6.46372 3.66854V6.52854C4.56731 7.20009 3.41384 9.12254 3.71372 11.1119L4.58456 16.401C5.00316 18.5274 6.89125 20.0441 9.05789 19.9944H12.6146C14.7812 20.0441 16.6693 18.5274 17.0879 16.401L17.9587 11.1119C18.2541 9.1125 17.0831 7.18641 15.1721 6.52854ZM11.7346 13.6877L11.9271 14.256C12.0115 14.5162 11.9674 14.8009 11.8082 15.0233C11.6491 15.2458 11.3938 15.3794 11.1204 15.3835H10.4421C10.1686 15.3794 9.91334 15.2458 9.75421 15.0233C9.59508 14.8009 9.55099 14.5162 9.63539 14.256L9.82789 13.6877C9.93168 13.3666 9.85069 13.0145 9.61706 12.771C9.09762 12.1539 9.14999 11.2387 9.73643 10.6849C10.3229 10.131 11.2396 10.131 11.826 10.6849C12.4125 11.2387 12.4648 12.1539 11.9454 12.771C11.7118 13.0145 11.6308 13.3666 11.7346 13.6877ZM7.83872 6.21687H8.16872V6.22604H13.7971V3.66854C13.7624 2.8872 13.1027 2.28083 12.3212 2.31187H9.31456C8.53307 2.28083 7.87343 2.8872 7.83872 3.66854V6.21687Z" fill="#083A5E" />
                                    </svg>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <div className={styles['iconDivLock']} >
                                        {showNewPassword ? (
                                            <BiHide onClick={() => setShowNewPassword(!showNewPassword)} style={{ cursor: "pointer" }} className={styles['eye-icon']} />
                                        ) : (
                                            <BiShow onClick={() => setShowNewPassword(!showNewPassword)} style={{ cursor: "pointer" }} className={styles['eye-icon']} />
                                        )}
                                    </div>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>

                <div className={styles.input_container}>
                    <label htmlFor="">Confirm Password</label>
                    {/* <input placeholder="Confirm Password" onChange={handlePassChange} value={saveChangedPassword.confirmPassword} className={styles.custom_input} type="text" name="confirmPassword" id="" /> */}
                    <CssTextField
                        className={styles.custom_input}
                        type={showConfirmPassword ? 'text' : 'password'}
                        // placeholder='Enter password'
                        name='confirmPassword'
                        placeholder="Confirm Password"
                        value={saveChangedPassword.confirmPassword}
                        onChange={handlePassChange}
                        required
                        // FormHelperTextProps={{ style: { color: '#d32f2f', width: '370px', marginLeft: '0px', } }}
                        // helperText={error}
                        // error={!!error}
                        autoComplete='new-password'
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M15.1721 6.52854V3.66854C15.1726 2.92138 14.869 2.20618 14.3313 1.68746C13.7935 1.16873 13.0679 0.891143 12.3212 0.918538H9.31456C8.5679 0.891143 7.84224 1.16873 7.3045 1.68746C6.76675 2.20618 6.46322 2.92138 6.46372 3.66854V6.52854C4.56731 7.20009 3.41384 9.12254 3.71372 11.1119L4.58456 16.401C5.00316 18.5274 6.89125 20.0441 9.05789 19.9944H12.6146C14.7812 20.0441 16.6693 18.5274 17.0879 16.401L17.9587 11.1119C18.2541 9.1125 17.0831 7.18641 15.1721 6.52854ZM11.7346 13.6877L11.9271 14.256C12.0115 14.5162 11.9674 14.8009 11.8082 15.0233C11.6491 15.2458 11.3938 15.3794 11.1204 15.3835H10.4421C10.1686 15.3794 9.91334 15.2458 9.75421 15.0233C9.59508 14.8009 9.55099 14.5162 9.63539 14.256L9.82789 13.6877C9.93168 13.3666 9.85069 13.0145 9.61706 12.771C9.09762 12.1539 9.14999 11.2387 9.73643 10.6849C10.3229 10.131 11.2396 10.131 11.826 10.6849C12.4125 11.2387 12.4648 12.1539 11.9454 12.771C11.7118 13.0145 11.6308 13.3666 11.7346 13.6877ZM7.83872 6.21687H8.16872V6.22604H13.7971V3.66854C13.7624 2.8872 13.1027 2.28083 12.3212 2.31187H9.31456C8.53307 2.28083 7.87343 2.8872 7.83872 3.66854V6.21687Z" fill="#083A5E" />
                                    </svg>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <div className={styles['iconDivLock']} >
                                        {showConfirmPassword ? (
                                            <BiHide onClick={() => setShowConfirmPassword(!showConfirmPassword)} style={{ cursor: "pointer" }} className={styles['eye-icon']} />
                                        ) : (
                                            <BiShow onClick={() => setShowConfirmPassword(!showConfirmPassword)} style={{ cursor: "pointer" }} className={styles['eye-icon']} />
                                        )}
                                    </div>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>

                {/* <button onClick={handleSubmit}> handleSubmit </button> */}
                {(saveChangedPassword.oldPassword || saveChangedPassword.newPassword || saveChangedPassword.confirmPassword) && <div style={{ alignSelf: 'start',width:"100%",marginTop:"20px" }}>
                    <button onClick={handleSubmit} className={styles.change_pass_btn}>Change Password</button>
                </div>}
            </div>
            {/* right side */}
            <div className={styles.change_pass_right_container}>
                <p>Password must contain:</p>
                <div>
                    <ul className={styles.list_container}>
                        <li className={styles.list_item}><IoIosCheckmarkCircle className={styles.icon_style} style={{ color: lengthCondition ? "#F27340" : "" }} />  <span style={{ fontSize: '15px' }}>at least 6 characters</span></li>
                        <li className={styles.list_item}><IoIosCheckmarkCircle className={styles.icon_style} style={{ color: oneNumCondition ? "#F27340" : "" }} />  <span style={{ fontSize: '15px' }}>at least one number</span></li>
                        <li className={styles.list_item}><IoIosCheckmarkCircle className={styles.icon_style} style={{ color: charsCondition ? "#F27340" : "" }} />  <span style={{ fontSize: '15px' }}>both uppercase and lowercase characters</span></li>
                        <li className={styles.list_item}><IoIosCheckmarkCircle className={styles.icon_style} style={{ color: specialCharCondition ? "#F27340" : "" }} />  <span style={{ fontSize: '15px' }}>Special Character</span></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}