import React from "react";
import { Routes, Route } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import styles from "./settings.module.css";
import { useEffect, useState } from "react";
import { Installation } from "./settings_components/Installation/Installation";
import { Account } from "./settings_components/Account/Account";
import MenuSideBar from "../../Components/MenuSidebar/MenuSideBar";
import Navbar from "../../Components/Navbar/Navbar";
import { Notifications } from "./settings_components/Notifications/Notifications";
import { Operators } from "./settings_components/Operators/Operators";
import { SettingsSideMenu } from "./settings_components/Settings_menu/SettingsSideMenu";
import * as api from '../../Service/API.js'; // Import your API functions
import { useAuth } from "../../Service/AuthContext.js";
import Appearance from "./settings_components/Appearance/Appearance.js";
import BotComponent from "./settings_components/Appearance/appearance_components/BotComponent.js";
import { useGlobalVariablesContext } from "../../Service/GlobalVariables";

export const Settings = () => {
    const [page, setPage] = useState("appearance");
    const [organizationData, setOrganizationData] = useState("")
    const [operatorAdded, setOperatorAdded] = useState(true)
    const [audioFilesData, setAudioFilesData] = useState([])
    const [color, setColor] = useState("#223952")
    const [buttonLabelChecked, setButtonLabelChecked] = useState(true);
    const { widgetID, userAttributes } = useAuth();
    const navigate = useNavigate();
    const [status, setStatus] = useState({ label: "Online", value: "online" })
    const { profilePicture } = useGlobalVariablesContext();
    const [botChatLabel, setBotChatLabel] = useState(true)


    const handleStatusChange = (status) => {
        if (status.value === "offline") {
            if (buttonLabelChecked) {
                setButtonLabelChecked(false)
                setBotChatLabel(true)
            }
        }
        else {
            if (botChatLabel) {
                setBotChatLabel(false)
                setButtonLabelChecked(true)
            }
        }
        setStatus(status)
    }

    const handleBotChatLabel = () => {
        if (buttonLabelChecked) {
            setButtonLabelChecked(false)
        }
        setBotChatLabel(!botChatLabel)
    }

    const handleUserChatLabel = () => {
        if (botChatLabel) {
            setBotChatLabel(false)
        }
        setButtonLabelChecked(!buttonLabelChecked)
    }

    const [botAppearanceData, setBotAppearanceData] = useState({
        botName: "Dianne Russel",
        botOnlineText: "Online",
        botAvatar: "",
        botChatTextTop: "BOT IS ONLINE",
        botChatTextBottom: "send message",
    })

    const [appearanceData, setAppearanceData] = useState({
        userName: userAttributes.name,
        // labelText:"",
        onlineText: "Online",
        topText: "WE ARE ONLINE",
        bottomText: "Send Message",
        color: "",
        buttonLabelChecked:buttonLabelChecked,
    })

    useEffect(() => {
        setAppearanceData(prevState => ({
            ...prevState,
            buttonLabelChecked: buttonLabelChecked,
        }));
    }, [buttonLabelChecked]);

    const handleBotInputChange = (e) => {

        setBotAppearanceData(prevData => ({
            ...prevData,
            [e.target.name]: e.target.value
        }))
    }

    

    const handleBotAvatarChange = (avatarUrl) => {
        console.log("preview avatar : ", avatarUrl)
        // setBotAppearanceData(prevData => ({
        //     ...prevData,
        //     botAvatar:avatarUrl
        // }))
        setBotAppearanceData({ ...botAppearanceData, botAvatar: avatarUrl })
        console.log("after avatar : ", botAppearanceData)
    }


    const handleInputChange = (e) => {

        setAppearanceData(prevData => ({
            ...prevData,
            [e.target.name]: e.target.value
        }))
    }


    const handleNavigation = (e) => {
        const selectedPage = e.currentTarget.id;
        setPage(selectedPage);
        navigate(`/settings/${selectedPage}`);
    };
    useEffect(() => {
        const fetchAudioData = async () => {
            try {
                const audioFiles = await api.listAudioFiles();
                console.log("audioFiles=====>", audioFiles);

                const modifyAndFilterObjects = (array) => array
                    .filter(obj => obj.name.trim() !== '')  // Remove objects without a name
                    .map(obj => ({
                        label: obj.name,
                        value: `https://d2ja7yn3ys8h9s.cloudfront.net/${obj.key}`
                    }));

                // Loop through each array in audioFiles, modify, and filter objects
                Object.keys(audioFiles).forEach(key => {
                    audioFiles[key] = modifyAndFilterObjects(audioFiles[key]);
                });

                setAudioFilesData(audioFiles)
            } catch (error) {
                console.error('Error fetching Data:', error);
            }
        };

        fetchAudioData();

    }, [widgetID]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const orgData = await api.fetchOrganizationData(widgetID);
                setOrganizationData(orgData[0])
            } catch (error) {
                console.error('Error fetching Data:', error);
            }
        };

        fetchData();
    }, [widgetID, operatorAdded]);


    useEffect(() => {
        const location = window.location.pathname
        console.log("location", location)

        if (location === "/settings") {
            navigate('/settings/appearance');
            setPage("appearance");
        } else {
            const match = location.match(/\/settings\/(\w+)/);
            if (match) {
                const extractedValue = match[1];
                setPage(extractedValue);
            }
            navigate(location);
        }

    }, []);


    const handleClick = async () => {
        console.log("Testing");
    };

    return (
        <>
            <MenuSideBar page={"Dashboard"} handleClick={handleClick} ShowIndicatior={true} />
            <div className="main-mychat">
                <Navbar />
                <div style={{ display: "flex" }}>
                    <div className={styles.settings_container}>
                        <SettingsSideMenu page={page} handleNavigation={handleNavigation} widthProp={page === "appearance" ? "20" : ""} />

                        {/* Use Routes and Outlet for nested routes */}
                        <Routes>
                            <Route path="appearance" element={<Appearance botColor={color} setColor={setColor} appearanceData={appearanceData} handleInputChange={handleInputChange} buttonLabelChecked={buttonLabelChecked} setButtonLabelChecked={setButtonLabelChecked} botAppearanceData={botAppearanceData} handleBotInputChange={handleBotInputChange} handleBotAvatarChange={handleBotAvatarChange} status={status} setStatus={setStatus} botChatLabel={botChatLabel} setBotChatLabel={setBotChatLabel} handleBotChatLabel={handleBotChatLabel} handleUserChatLabel={handleUserChatLabel} />} />
                            <Route path="account" element={<Account />} />
                            <Route path="installation" element={<Installation organizationData={organizationData} />} />
                            <Route path="notification" element={<Notifications audioFilesData={audioFilesData} />} />
                            <Route path="operators" element={<Operators operatorAdded={operatorAdded} setOperatorAdded={setOperatorAdded} organizationData={organizationData} />} />
                        </Routes>

                        {/* bot component */}
                        {
                            page === "appearance" && <BotComponent color={color} appearanceData={appearanceData} botAppearanceData={botAppearanceData} buttonLabelChecked={buttonLabelChecked} status={status} setStatus={setStatus} profilePicture={profilePicture} botChatLabel={botChatLabel} setBotChatLabel={setBotChatLabel} setButtonLabelChecked={setButtonLabelChecked} handleStatusChange={handleStatusChange} />
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

