import React, { useState, useEffect, useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { QuestionIcon } from '../icons/QuestionIcon';
import styles from "./basicTable.module.css"
import { AiOutlineWarning } from "react-icons/ai";
import CustomSelect from '../../../../../Components/CustomSelect/CustomSelect';
import * as api from '../../../../../Service/API.js';
import { FaVolumeUp } from "react-icons/fa";
import { useSound } from '../../../../../Service/SoundContext';
import { useAuth } from '../../../../../Service/AuthContext.js';



// function createData(name, calories, fat, carbs, protein) {
//     return { name, calories, fat, carbs, protein };
// }

// const rows = [
//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//     createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//     createData('Eclair', 262, 16.0, 24, 6.0),
//     createData('Cupcake', 305, 3.7, 67, 4.3),
//     createData('Gingerbread', 356, 16.0, 49, 3.9),

//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//     createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//     createData('Eclair', 262, 16.0, 24, 6.0),
//     createData('Cupcake', 305, 3.7, 67, 4.3),
//     createData('Gingerbread', 356, 16.0, 49, 3.9),

//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//     createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//     createData('Eclair', 262, 16.0, 24, 6.0),
//     createData('Cupcake', 305, 3.7, 67, 4.3),
//     createData('Gingerbread', 356, 16.0, 49, 3.9),
// ];


export default function BasicTable({ audioFilesData, notificationsSoundData, setNotificationsSoundData,trackSounds, setTrackSounds }) {
    const options = audioFilesData //Dropdown Options

    // const [notificationsSoundData, setNotificationsSoundData] = useState(soundData) //Defulat user sounds

    console.log("...... Dropdown options", options) //Dropdown Options
    console.log("...... user soundData", notificationsSoundData) //Defulat user sounds


    const [newMessageVolumeIcon, setNewMessageVolumeIcon] = useState(false)
    const [newVisitorVolumeIcon, setNewVisitorVolumeIcon] = useState(false)
    const [newChatVolumeIcon, setNewChatVolumeIcon] = useState(false)
    const [newActiveChatVolumeIcon, setNewActiveChatVolumeIcon] = useState(false)

    const [changeAudio, setChangeAudio] = useState({
        newVisitorLandAudioChange:false,
        newActiveMessageAudioChange:false,
        newInactiveMessageAudioChange:false,
        newChatRequestAudioChange:false
    })

    console.log("change audio : ", changeAudio)

    const handleInactiveMessageAudio = (selectedOption) => {
        if(!selectedOption){
            setTrackSounds({...trackSounds, newInactiveMessageAudioChange:false})
        }else{
            setTrackSounds({...trackSounds, newInactiveMessageAudioChange:true})
        }
        const selected = selectedOption ? selectedOption.value : "";
        console.log("selected", selected);

        if (selected && selectedOption.value) {
            const audio = new Audio(selected);


            setNewMessageVolumeIcon(true);

            audio.addEventListener('ended', () => {
                setNewMessageVolumeIcon(false);
            });

            audio.play();
            changeAudio.newInactiveMessageAudioChange = true
            setChangeAudio(changeAudio)
            handleNotificationsSoundData("Inactive chat message", selectedOption)
        }
    };

    const handleNewVisitorAudio = (selectedOption) => {
        if(!selectedOption){
            setTrackSounds({...trackSounds, newVisitorLandAudioChange:false})
        }else{
            setTrackSounds({...trackSounds, newVisitorLandAudioChange:true})
        }
        const selected = selectedOption ? selectedOption.value : "";
        console.log("selected", selectedOption);

        if (selected && selectedOption.value) {
            const audio = new Audio(selected);


            setNewVisitorVolumeIcon(true);

            audio.addEventListener('ended', () => {
                setNewVisitorVolumeIcon(false);
            });

            audio.play();
            changeAudio.newVisitorLandAudioChange = true
            setChangeAudio(changeAudio)
            handleNotificationsSoundData("New vistor lands", selectedOption)

        }
    };

    const handleNewChatRequestAudio = (selectedOption) => {
        if(!selectedOption){
            setTrackSounds({...trackSounds, newChatRequestAudioChange:false})
        }else{
            setTrackSounds({...trackSounds, newChatRequestAudioChange:true})
        }
        const selected = selectedOption ? selectedOption.value : "";
        console.log("selected", selected);

        if (selected && selectedOption.value) {
            const audio = new Audio(selected);

            setNewChatVolumeIcon(true);

            audio.addEventListener('ended', () => {
                setNewChatVolumeIcon(false);
            });

            audio.play();
            changeAudio.newChatRequestAudioChange = true
            setChangeAudio(changeAudio)
            handleNotificationsSoundData("New chat request", selectedOption)
        }
    };

    const handleActiveMessageAudio = (selectedOption) => {
        if(!selectedOption){
            setTrackSounds({...trackSounds, newActiveMessageAudioChange:false})
        }else{
            setTrackSounds({...trackSounds, newActiveMessageAudioChange:true})
        }
        const selected = selectedOption ? selectedOption.value : "";
        console.log("selected", selected);

        if (selected && selectedOption.value) {
            const audio = new Audio(selected);


            setNewActiveChatVolumeIcon(true);

            audio.addEventListener('ended', () => {
                setNewActiveChatVolumeIcon(false);
            });

            audio.play();
            changeAudio.newActiveMessageAudioChange = true
            setChangeAudio(changeAudio)
            handleNotificationsSoundData("Active chat message", selectedOption)

        }
    };


    const handleNotificationsSoundData = (type, selectedOption) => {

        for(let i=0; i<notificationsSoundData.length; i++){
            if (notificationsSoundData[i].type === type) {
                console.log("user selected select new chat request: ", type, selectedOption)
                notificationsSoundData[i] = { ...notificationsSoundData[i], name: selectedOption.label, url: selectedOption.value }
                break
            }
        }
        console.log("sound after updating value : ", notificationsSoundData)
        setNotificationsSoundData(notificationsSoundData)
        console.log("sound after changing state updating value : ", notificationsSoundData)
    }

    let newChat;
    let newVisitor;
    let inActiveChat;
    let activeChat;


    notificationsSoundData.map(sound => {
        if (sound.type === "New chat request") {
            newChat = { label: sound.name, value: sound.url }
        }
        if (sound.type === "New vistor lands") {
            newVisitor = { label: sound.name, value: sound.url }
        }
        if (sound.type === "Inactive chat message") {
            inActiveChat = { label: sound.name, value: sound.url }
        }
        if (sound.type === "Active chat message") {
            activeChat = { label: sound.name, value: sound.url }
        }
    })


    return (
        <TableContainer component={Paper} className={styles.table_container} style={{ maxHeight: "420px", overflowX: "unset" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className={styles.table_heading}>Notification type</TableCell>
                        <TableCell className={styles.table_heading}>Email <span className={styles.question_icon_container}><QuestionIcon /></span></TableCell>
                        <TableCell className={styles.table_heading} >Web notification <span className={styles.question_icon_container}><QuestionIcon /></span></TableCell>
                        <TableCell className={styles.table_heading}>Web notification sound</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>


                    <TableRow>
                        <TableCell>New Vistor Lands</TableCell>
                        <TableCell><input className={styles.checkbox} disabled type="checkbox" name="" id="" /></TableCell>
                        <TableCell><input className={styles.checkbox} checked disabled type="checkbox" name="" id="" /></TableCell>
                        <TableCell>
                            <div className={styles.dropdownContainer}>
                                <div className={styles.volume_IconContainer}>
                                    {newVisitorVolumeIcon && <FaVolumeUp className={newVisitorVolumeIcon ? styles.fadeIn : styles.fadeOut} />}
                                </div>

                                <CustomSelect onChange={handleNewVisitorAudio} width={"300px"} options={options.messages} selectedValueProp={newVisitor} borderColor={changeAudio.newVisitorLandAudioChange ? "#00cc00" : ""} />
                            </div>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>Inactive chat message</TableCell>
                        <TableCell><input className={styles.checkbox} disabled type="checkbox" name="" id="" /></TableCell>
                        <TableCell><input className={styles.checkbox} checked disabled type="checkbox" name="" id="" /></TableCell>
                        <TableCell>
                            <div className={styles.dropdownContainer}>
                                <div className={styles.volume_IconContainer}>
                                    {newMessageVolumeIcon && <FaVolumeUp className={newMessageVolumeIcon ? styles.fadeIn : styles.fadeOut} />}
                                </div>
                                <CustomSelect width={"300px"} onChange={handleInactiveMessageAudio} options={options.messages} selectedValueProp={inActiveChat} borderColor={changeAudio.newInactiveMessageAudioChange ? "#00cc00" : ""} />
                            </div>
                        </TableCell>
                    </TableRow>


                    <TableRow>
                        <TableCell>New chat request</TableCell>
                        <TableCell><input className={styles.checkbox} disabled type="checkbox" name="" id="" /></TableCell>
                        <TableCell><input className={styles.checkbox} checked disabled type="checkbox" name="" id="" /></TableCell>
                        <TableCell>
                            <div className={styles.dropdownContainer}>
                                <div className={styles.volume_IconContainer}>
                                    {newChatVolumeIcon && <FaVolumeUp className={newChatVolumeIcon ? styles.fadeIn : styles.fadeOut} />}
                                </div>

                                <CustomSelect onChange={handleNewChatRequestAudio} width={"300px"} options={options.Alert} selectedValueProp={newChat} borderColor={changeAudio.newChatRequestAudioChange ? "#00cc00" : ""} />
                            </div>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>Active chat message</TableCell>
                        <TableCell><input className={styles.checkbox} disabled type="checkbox" name="" id="" /></TableCell>
                        <TableCell><input className={styles.checkbox} checked disabled type="checkbox" name="" id="" /></TableCell>
                        <TableCell>
                            <div className={styles.dropdownContainer}>
                                <div className={styles.volume_IconContainer}>
                                    {newActiveChatVolumeIcon && <FaVolumeUp className={newActiveChatVolumeIcon ? styles.fadeIn : styles.fadeOut} />}
                                </div>
                                <CustomSelect width={"300px"} onChange={handleActiveMessageAudio} options={options.messages} selectedValueProp={activeChat} borderColor={changeAudio.newActiveMessageAudioChange ? "#00cc00" : ""} />
                            </div>
                        </TableCell>
                    </TableRow>

                    {/* <TableRow>
                        <TableCell><div>Incoming visitor</div>
                            <span className={styles.fa_inactive}><AiOutlineWarning className={styles.warning_sign} />You won’t receive any notifications of this type.</span>
                        </TableCell>
                        <TableCell className={styles.table_heading}>Not available</TableCell>
                        <TableCell><input className={styles.checkbox} type="checkbox" name="" id="" /></TableCell>
                        <TableCell>
                            <div className={styles.dropdownContainer}>
                                <CustomSelect width={"300px"} options={options} />
                            </div>
                        </TableCell>
                    </TableRow> */}

                </TableBody>
            </Table>
        </TableContainer>
    );
}