import React, { useEffect, useState } from 'react';
import './App.css'
import socket from './socketjs';

import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import * as api from './Service/API.js';

import AppRoutes from './Routes/AppRoutes.js';
import BottomNotification from './Components/BottomNotification/BottomNotification.js';

import Backdrop from '@mui/material/Backdrop';
import { useAuth } from './Service/AuthContext.js';
import { useNavigate } from 'react-router-dom';
import { useConnectedUsersContext } from './Service/ConnectedUsersContext.js';
import { useUser } from './Service/SelectedUserContext.js';
import { useSound } from './Service/SoundContext.js';
import { useGlobalVariablesContext } from './Service/GlobalVariables.js';
import { styled } from '@mui/material/styles';
import loader_animation from "../src/Img/Comcate_blue_animation.gif"



Amplify.configure(awsExports);

function App() {
  const { setAuthData, setWidgetData } = useAuth();
  const navigate = useNavigate();
  const { updateCardList,cardList } = useGlobalVariablesContext();
  const [userName, setUserName] = useState('');
  const [usersList, setUsersList] = useState([]);
  const [disconnectedUserList, setDisconnectedUserList] = useState([]);
  const { connectedUsers, updateConnectedUsers, updateDisconnectedUsers } = useConnectedUsersContext();
  const [notifications, setNotifications] = useState([]);
  const [isNewMessage, setIsNewMessage] = useState(false);
  const { selectedUser } = useUser();
  const [fromMessage, setFromMessage] = useState(false);
  const { playSound, soundData, handleUpdateSounds, } = useSound();
  const [changesInProgress, setChangesInProgress] = useState(false);
  const authToken = "ComcatChatbotApi123$"

  const { showIndicatorNewUser, setShowIndicatorNewUser } = useGlobalVariablesContext();
  const { showIndicatorNewMessage, setShowIndicatorNewMessage, setOnlineStatus } = useGlobalVariablesContext();
  const { setGlobalProfilePicture } = useGlobalVariablesContext();
  const [imageUrl, setImageUrl] = useState(null);
  const [currentUserInfo, setCurrentUserInfo] = useState({
    sessionID: '',
    socketID: '',
    auth: '',
    username:''
  });

  const newChatRequest = soundData.find(item => item.type === 'New chat request');
  const [audio] = useState(new Audio());

  // useEffect to update audio URL when newChatRequest changes
  useEffect(() => {
    if (newChatRequest) {
      audio.src = newChatRequest.url;
      audio.loop = true;
    }
  }, [newChatRequest, audio]);

  const playAudio = async () => {
    StopAudio();  // Stothe currently playing audio
    audio.loop = true;
    await audio.play();
  };

  const StopAudio = async () => {
    audio.loop = false;
    audio.pause();
    audio.currentTime = 0;
  };

  const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundImage: `url(${loader_animation})`, // Set the custom GIF as the background image
    backgroundSize: '260px 250px', // Set width and height of the background image
    backgroundPosition: 'center', // Center the background image
    backgroundRepeat: "no-repeat",
    backdropFilter: "contrast(0.5)",
    width: '100vw', // Set width to cover the entire viewport width
    height: '100vh', // Set height to cover the entire viewport height
  }));


  const socketConnect = async (username, sessionID, attributes, isReturning) => {
    try {
      console.log("username", username);
      console.log("sessionID", sessionID);
      console.log("attributes", attributes);
      console.log("isReturning", isReturning);
  
      setChangesInProgress(true);
      setAuthData(attributes);
      setUserName(username);
  
      const UserGroup = 'admin';
      const widgetID = attributes['custom:WidgetID'];
  
      console.log("userGroup", UserGroup);
      console.log("widgetID", widgetID);
      setWidgetData(widgetID);
  
      console.log("===== Fetching Data before====");
  
      // Concurrent data fetching
      await Promise.all([
        fetchProfilePicture(attributes),
        fetchDisconnectedUsers(widgetID),
        fetchBotflowData(widgetID),
        fetchSoundData(attributes),
        fetchAdminOnlineStatus(attributes)
      ]);
  
      console.log("===== Fetching Data after====");
  
      // Combining socket auth data setting and connection
      const socketAuthData = {
        username,
        sessionID,
        UserGroup,
        attributes,
        widgetID
      };
  
      socket.auth = socketAuthData;
      console.log("===== socket.connect(); is activated before====");
      socket.connect();
      console.log("===== socket.connect(); is after ====");
  
      setCurrentUserInfo({
        sessionID: socket.auth.sessionID,
        socketID: socket.id,
        auth: socket.auth,
        username: username,
      });
  
      if (!isReturning) {
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error in socketConnect:', error);
    } finally {
      setChangesInProgress(false);
    }
  };
  


  const createSession = async (username, attributes) => {
    try {
      const data = await api.createSession(username, attributes);
      console.log("=====createSession is activated ====")
      const isReturning = false
      socketConnect(data.username, data.sessionID, attributes, isReturning);

      localStorage.setItem('session-username-admin', data.username);
      localStorage.setItem('session-userID-admin', data.sessionID);
    } catch (error) {
      console.error('Error creating session:', error);
    }
  };

  const handleUsersEvent = (users) => {
    const updatedUsers = users.map((user) => ({
      ...user,
      self: user.userID === socket.id,
    }));

    const sortedUsers = updatedUsers.sort((a, b) => {
      if (a.self) return -1;
      if (b.self) return 1;
      return a.username.localeCompare(b.username);
    });

    setUsersList(sortedUsers);

  };



  const fetchSoundData = async (attributes) => {
    try {

      if (attributes && attributes.email) {
        const username = attributes.email
        const getResponse = await api.fetchSounds(username);
        const soundArray = getResponse['sounds']
        handleUpdateSounds(soundArray)
      } else {
        console.log("userAttributes is null or empty");
      }
    } catch (error) {
      console.error('Error fetching sound for user:', error);
    }
  };

  const fetchBotflowData = async (widgetID) => {
    try {
      const getResponse = await api.fetchBotMessages(widgetID);
      const botMessage = getResponse[0].data
      updateCardList(botMessage);
    } catch (error) {
      console.error('Error fetching disconnected user:', error.message);
    }
  };



  const fetchAdminOnlineStatus = async (attributes) => {
    try {

      if (attributes && attributes.email) {
        const username = attributes.email
        const getResponse = await api.getOnlineStatus(username);
        console.log("fetchAdminOnlineStatus", getResponse.onlineStatus)
        setOnlineStatus(getResponse.onlineStatus)

      } else {
        console.log("userAttributes is null or empty");
      }
    } catch (error) {
      console.error('Error fetching sound for user:', error);
    }
  };


  const handleUserConnectedEvent = (user) => {
    if (user.UserGroup !== 'admin') {
      setUsersList((prevUsers) => [...prevUsers, user]);
    }
    if (user.UserGroup !== "admin") {
      console.log("Sound Played?")
      playSound('New vistor lands');
    }
  };

  const fetchProfilePicture = async (userAttributes) => {


    try {
      const response = await axios.get('https://stampa.zirvik.com/getProfilePicture', {
        params: {
          username: userAttributes.email,
        },
        headers: {
          Authorization: authToken,
        },
      });
      console.log('Success:', response.data.data.profilePicture);

      if (response.data.data.profilePicture === undefined) {
        setGlobalProfilePicture(null);
      }

      const uintArray = new Uint8Array(response.data.data.profilePicture.data);
      const binary = uintArray.reduce((str, byte) => str + String.fromCharCode(byte), '');
      const b64 = btoa(binary);
      const newImageUrl = `data:image/png;base64,${b64}`

      setGlobalProfilePicture(newImageUrl);
    } catch (error) {
      // Handle errors
      console.log("Not", error)
      console.error('Error:', error.response ? error.response.data : error.message);
    }
  };

  const fetchDisconnectedUsers = async (widgetID) => {
    try {
      const data = await api.fetchDisconnectedUsers(widgetID);
      updateDisconnectedUsers(data);
    } catch (error) {
      console.error('Error fetching disconnected user:', error.message);
    }
  };

  const uniqueArray = usersList.filter(
    (obj, index, self) => index === self.findIndex((t) => t.sessionID === obj.sessionID)
  );

  const handleStartChat = async (user) => {

    setOnlineStatus(true)
    const userAttributes = currentUserInfo.auth.attributes


    const UserSessionID = user.sessionID
    const updatedUsers = connectedUsers.map((connectedUser) => {
      if (connectedUser.sessionID === UserSessionID) {
        return { ...connectedUser, isActiveChat: true, adminAssigned: userAttributes.name,adminAssignedUsername: userAttributes.email };
      }
      return connectedUser;
    });

    console.log("userAttributes ====>", userAttributes)

    socket.emit('chatStarted', {
      updatedUsers,
      sessionID: UserSessionID,
      adminAssigned: userAttributes.name,
      username: userAttributes.email,
      widgetID: userAttributes['custom:WidgetID'],
      notificationID: user.id
    });

    updateConnectedUsers(updatedUsers);


    const userToActiveChat = connectedUsers.find((user) => user.sessionID === UserSessionID);

    setNotifications((prevNotifications) => prevNotifications.filter((notification) => notification.id !== user.id));
    StopAudio();
    navigate('/activechat', { state: { userToActiveChat } });
  };


  const closeNotification = (id) => {
    StopAudio();
    setNotifications((prevNotifications) => prevNotifications.filter((notification) => notification.id !== id));

  };

  const fetchData = async () => {
    console.log("Fetch data Running",);
    try {
      setChangesInProgress(true)
      const sessUsername = localStorage.getItem('session-username-admin');
      const sessUserID = localStorage.getItem('session-userID-admin');

      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      const sessAttributes = user.attributes;
      setAuthData(sessAttributes);
      console.log("sessAttributes ===>", sessAttributes);

      if (sessUsername && sessUserID) {
        const isReturning = true
        socketConnect(sessUsername, sessUserID, sessAttributes, isReturning);
      }
    } catch (error) {
      console.error("Error fetching authenticated user:", error);
      setChangesInProgress(false)
    }
  };




  useEffect(() => {
    updateConnectedUsers(uniqueArray);
  }, [usersList]);


  useEffect(() => {
    const privateMessageListener = (data) => {
      const { content, from } = data;


      setTimeout(() => {
        const messageFromUser = connectedUsers.find((user) => user.sessionID === from);

        console.log("messageFromUser", messageFromUser)
        if (
          messageFromUser &&
          messageFromUser.adminAssignedUsername === "N/A" ||
          messageFromUser.adminAssignedUsername === "null" ||
          messageFromUser.adminAssignedUsername === ""
        ) {
          const { username, visitlink, sessionID, country_code2 } = messageFromUser;

          // Use the functional form of setNotifications to get the latest state
          setNotifications((prevNotifications) => {
            // Check if a notification with the same username and sessionID already exists
            const notificationExists = prevNotifications.some(
              (notification) => notification.username === username && notification.sessionID === sessionID
            );

            if (!notificationExists) {
              const newNotification = {
                username,
                sessionID,
                visitlink,
                content,
                country_code2,
                id: content.length > 10 ? content.slice(0, 10) + sessionID : content + sessionID
              };


              const updatedNotifications = [...prevNotifications, newNotification];
              playAudio();


              if (updatedNotifications.length > 4) {
                return updatedNotifications.slice(1);
              } else {
                return updatedNotifications;
              }
            }

            // Return the previous notifications if the new notification shouldn't be added
            return prevNotifications;
          });
        }
      }, 0);
    };

    socket.on('Emit_Message_to_all_admins_from_server', privateMessageListener);

    return () => {
      socket.off('Emit_Message_to_all_admins_from_server', privateMessageListener);
    };
  }, [socket, connectedUsers]);



  useEffect(() => {
    socket.on('users_to_admin', handleUsersEvent);
    socket.on('user connected', handleUserConnectedEvent);

    return () => {
      socket.off('users_to_admin', handleUsersEvent);
      socket.off('user connected', handleUserConnectedEvent);
    };
  }, [socket]);

  useEffect(() => {
    const handleDisconnectedUsers = (data) => {
      updateDisconnectedUsers(data.UserstoClient);
    };

    socket.on('disconnected-users', handleDisconnectedUsers);


    return () => {
      socket.off('disconnected-users', handleDisconnectedUsers);
    };
  }, []);

  useEffect(() => {

    if (selectedUser && fromMessage === selectedUser.sessionID && isNewMessage === true) {
      playSound('Active chat message');
    } else if (isNewMessage === true) {
      playSound('Inactive chat message');
    }
    setIsNewMessage(false);
  }, [isNewMessage, fromMessage, selectedUser, showIndicatorNewMessage]);



  useEffect(() => {
    socket.on('messageSound', ({ content, from }) => {
      if (content) {
        setIsNewMessage(true);
        setFromMessage(from);
        if (window.location.pathname !== '/activechat') {
          setShowIndicatorNewMessage(true);
        }
      }
    });

    return () => {
      socket.off('messageSound');
    };
  }, []);


  
  useEffect(() => {
    const ChatListener = (data) => {
      StopAudio();
      closeNotification(data.notificationID);
    };

    socket.on('Chat_has_already_started', ChatListener);

    return () => {
      socket.off('Chat_has_already_started', ChatListener);
    };
  }, [socket, notifications]);


  const isLoginOrSignupScreen = window.location.pathname === '/login' || window.location.pathname === '/signup';

  return (
    <div className="App">
      <StyledBackdrop open={changesInProgress}>

      </StyledBackdrop>



      {!isLoginOrSignupScreen && currentUserInfo.auth && (
        notifications.map((notification, index) => (
          <BottomNotification
            key={notification.id}
            index={index}
            onReplyClick={() => handleStartChat(notification)}
            onClose={() => closeNotification(notification.id)}
            {...notification}
          />
        ))
      )}

      <AppRoutes
        userName={userName}
        currentUserInfo={currentUserInfo}
        disconnectedUserList={disconnectedUserList}
        createSession={createSession}
        fetchData={fetchData}
      />
    </div>
  );
}



export default App;
