import React, { useState, useEffect, useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { QuestionIcon } from '../LiveIcons/QuestionIcon';
import { TbWorld } from "react-icons/tb";
import LaptopIcon from '@mui/icons-material/Laptop';
import slash_50 from "../LiveIcons/slash-50.png"
import styles from "./basicTable.module.css"
import { OnlineStatusIcon } from '../LiveIcons/OnlineStatusIcon';
import CountryFlag from 'react-country-flag';
import { Avatar } from '@mui/material';
import socket from '../../../socketjs';
import chrome from '../../../Img/chrome.png';
import safari from '../../../Img/safari.png';
import microsoft from '../../../Img/microsoft.png';
import firefox from '../../../Img/firefox.png';
import { useNavigate } from 'react-router-dom';
import { useConnectedUsersContext } from '../../../Service/ConnectedUsersContext';
import { useAuth } from '../../../Service/AuthContext';
import { FaUser } from 'react-icons/fa';
import * as api from '../../../Service/API'; // Import your API functions
import { IoReturnDownForward } from "react-icons/io5";
import { BsChatRightDotsFill } from "react-icons/bs";
import { FaLongArrowAltDown } from "react-icons/fa";
import { FaLongArrowAltUp } from "react-icons/fa";
import Tooltip from '@mui/material/Tooltip';
import { useGlobalVariablesContext } from '../../../Service/GlobalVariables';
import menu from '../../../Img/menu.png';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { IoIosClose } from "react-icons/io";
import ActivityInfo from '../../Conversation/Conversation_components/ActivityInfo/ActivityInfo';
import chatBackround from '../../../Img/chatBackround.png';


// function createData(name, calories, fat, carbs, protein) {
//     return { name, calories, fat, carbs, protein };
// }

// const rows = [
//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//     createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//     createData('Eclair', 262, 16.0, 24, 6.0),
//     createData('Cupcake', 305, 3.7, 67, 4.3),
//     createData('Gingerbread', 356, 16.0, 49, 3.9),

//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//     createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//     createData('Eclair', 262, 16.0, 24, 6.0),
//     createData('Cupcake', 305, 3.7, 67, 4.3),
//     createData('Gingerbread', 356, 16.0, 49, 3.9),

//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//     createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//     createData('Eclair', 262, 16.0, 24, 6.0),
//     createData('Cupcake', 305, 3.7, 67, 4.3),
//     createData('Gingerbread', 356, 16.0, 49, 3.9),
// ];


export default function BasicTable({ selectedFilter }) {
    const [currentTime, setCurrentTime] = useState(new Date());
    const { connectedUsers, updateConnectedUsers } = useConnectedUsersContext();
    const { setOnlineStatus } = useGlobalVariablesContext();
    const { widgetID, userAttributes } = useAuth();
    const [sortOrder, setSortOrder] = useState('desc'); // 'desc' for descending, 'asc' for ascending

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);




    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            backgroundColor: '#44b700',
            color: '#44b700',
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                animation: 'ripple 1.2s infinite ease-in-out',
                border: '1px solid currentColor',
                content: '""',
            },
        },
        '@keyframes ripple': {
            '0%': {
                transform: 'scale(.8)',
                opacity: 1,
            },
            '100%': {
                transform: 'scale(2.4)',
                opacity: 0,
            },
        },
    }));




    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleStartChat = async (user) => {
        const updatedUsers = connectedUsers.map((connectedUser) => {
            if (connectedUser.sessionID === user.sessionID) {
                return { ...connectedUser, isActiveChat: true, adminAssigned: userAttributes.name, adminAssignedUsername: userAttributes.email };
            }
            return connectedUser;


        });

        console.log("updatedUsers", updatedUsers);
        setOnlineStatus(true)

        socket.emit('chatStarted', {
            updatedUsers,
            sessionID: user.sessionID,
            adminAssigned: userAttributes.name,
            username: userAttributes.email,
            widgetID: widgetID,
        });

        updateConnectedUsers(updatedUsers);
        const userToActiveChat = user
        navigate('/activechat', { state: { userToActiveChat } });
    };



    const getBrowserIcon = (browserName, size) => {
        console.log('Size:', size); // Log the size parameter to check its value
        let icon = null;
        switch (browserName.toLowerCase()) {
            case 'chrome':
                icon = chrome;
                break;
            case "edge":
                icon = microsoft;
                break;
            case "safari":
                icon = safari;
                break;
            case "firefox":
                icon = firefox;
                break;
            default:
                icon = chrome; // You can use any other icon for unknown browsers
        }
        console.log('Icon:', icon); // Log the selected icon to check if it's valid
        return <img src={icon} className={size === "visit_info_modal" ? styles.visitInfoIcon : styles.icon} alt={browserName} />;
    }


    // const extractLastSegment = (url) => {

    //     console.log("url===>>>>",url)

    //     const path = new URL(url).pathname;
    //     if (path !== '/') {
    //         return `/${path.substring(1)}`;
    //     } else {
    //         return new URL(url).hostname;
    //     }
    // };



    const uniqueList = useMemo(
        () =>
            connectedUsers
                .filter((obj, index, self) => index === self.findIndex((o) => o.key === obj.key))
                .filter((obj) => obj.userID),
        [connectedUsers]
    );

    useEffect(() => {

        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 60000);


        return () => clearInterval(intervalId);
    }, []);

    const formatTimeDifference = (timestamp) => {
        const now = currentTime;
        const landingTime = new Date(timestamp);
        const timeDifferenceInSeconds = Math.floor((now - landingTime) / 1000);

        if (timeDifferenceInSeconds < 60) {
            return `${Math.max(timeDifferenceInSeconds, 10)} seconds ago`;
        } else if (timeDifferenceInSeconds < 3600) {
            const minutes = Math.floor(timeDifferenceInSeconds / 60);
            return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
        } else {
            const hours = Math.floor(timeDifferenceInSeconds / 3600);
            return `${hours} hour${hours === 1 ? '' : 's'} ago`;
        }
    };


    const renderTableContent = () => {

        let filteredList = uniqueList;

        if (selectedFilter === 'Returning') {
            filteredList = filteredList.filter((user) => user.VisitCounter > 0);
        } else if (selectedFilter === 'Assigned') {
            filteredList = filteredList.filter((user) => user.adminAssigned !== 'N/A');
        } else if (selectedFilter === 'Unassigned') {
            filteredList = filteredList.filter((user) => user.adminAssigned === 'N/A');
        }


        const sortedList = filteredList.slice().sort((a, b) => {
            const timestampA = new Date(a.timestamp).getTime();
            const timestampB = new Date(b.timestamp).getTime();

            return sortOrder === 'desc' ? timestampB - timestampA : timestampA - timestampB;
        });


        if (sortedList.length === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={7} align="center">
                        No users online at the moment
                    </TableCell>
                </TableRow>
            );
        }

        return sortedList.map((row) => {
            console.log("Row : ", row)
            return <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell >
                    {/* <OnlineStatusIcon /> */}
                    <div style={{ display: "flex", gap: "10px" }}>
                        <div style={{ position: 'relative', display: 'inline-block' }}>
                            <Avatar sx={{ bgcolor: "#223952", width: "35px", height: "35px" }}>{row.username.charAt(0).toUpperCase()}</Avatar>
                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: '0',
                                    right: '0',
                                    width: '12px',
                                    height: '12px',
                                    backgroundColor: '#00cd00',
                                    boxShadow: "1px 1px 7px 0 rgba(101, 101, 101, 0.2), 1px 1px 7px 0 rgba(100, 100, 100, 0.19)",
                                    borderRadius: '50%',
                                }}
                            />
                        </div>
                        <div className={styles.username_container}>
                            <span className={styles.username}>{row.username}</span>
                            {row.VisitCounter > 0 ? (
                                <span className={styles.visits_status}><IoReturnDownForward /> Returning</span>
                            ) : null}

                        </div>
                    </div>


                </TableCell>
                <TableCell className={styles.visit_time}>{formatTimeDifference(row.timestamp)}</TableCell>
                <TableCell>
                    {<div className={styles.visit_info}>
                        {row.country_code2 && <CountryFlag className={styles.icon} countryCode={row.country_code2} svg />}
                        {/* <img className={styles.icon} src={getBrowserIcon(row.browser.split(" ")[0])} alt="" /> */}
                        {getBrowserIcon(row.browser.split(" ")[0])}
                        {/* Render something else if the country code is not available */}
                    </div>}
                </TableCell>
                <TableCell className={styles.visit_link}>
                    <a href={row.visitlink} target="_blank" rel="noopener noreferrer">
                        {/* {extractLastSegment(row.visitlink)} */}

                        {row.visitlink}
                    </a>
                </TableCell>
                <TableCell>
                    {row.adminAssigned === "N/A" ? (
                        <p>N/A</p>
                    ) : (
                        <>
                            <div className={styles.assigned_Operator}>
                                <FaUser className={styles.user_icon} />
                                {row.adminAssigned}
                            </div>

                        </>
                    )}
                </TableCell>

                <TableCell style={{ textAlign: "-webkit-left" }} align='left'>
                    {(row.isActiveChat || row.isChatClosed) && (
                        <button
                            className={row.isActiveChat ? styles.inActive_chat_btn : row.isChatClosed ? styles.close_chat_btn : null}
                            disabled={true}
                        >
                            {/* {!row.isActiveChat && (<div><BsChatRightDotsFill style={{ fontSize: "15px", color: "ffffff" }} /> </div>)} */}
                            {row.isActiveChat ? 'In Active Chat' : row.isChatClosed ? "Chat Closed" : null}
                        </button>
                    )}
                    {(!row.isActiveChat && !row.isChatClosed) && (
                        <p>N/A</p>
                    )}


                </TableCell>
                <TableCell style={{ textAlign: "-webkit-right" }} align='right'>
                    {!row.isActiveChat && (
                        <button
                            onClick={() => {
                                if (!row.isActiveChat) {
                                    handleStartChat(row);
                                }
                            }}
                            className={row.isActiveChat ? styles.inActive_chat_btn : styles.start_chat_btn}
                            disabled={row.isActiveChat}
                        >
                            {/* {!row.isActiveChat && (<div><BsChatRightDotsFill style={{ fontSize: "15px", color: "ffffff" }} /> </div>)} */}
                            {row.isChatClosed ? "Restart Chat" : "Start Chat"}
                        </button>
                    )}



                </TableCell>
                <TableCell style={{ textAlign: "-webkit-right" }} align='right'>
                    <div>
                        <img
                            src={menu}
                            alt="menu icon"
                            style={{ width: "20px", cursor: "pointer" }}
                            onClick={handleOpen}
                        />
                    </div>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{ timeout: 500 }}
                    >
                        <Fade in={open}>
                            <div className={styles.modalContainer}>
                                <div className={styles.modalHeader} style={{ backgroundImage: `url(${chatBackround})` }}>
                                    <div className={styles.headerUserInfo}>


                                        <StyledBadge
                                            overlap="circular"
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                            variant="dot"
                                        >
                                            <Avatar sx={{ bgcolor: "#5353539c" }}>{row.username.charAt(0).toUpperCase()}</Avatar>
                                        </StyledBadge>

                                        <div className={styles.visitInfoContainer}>
                                            <div className={styles.visitInfo}>
                                                {row.username}


                                                {row.country_code2 && <CountryFlag className={styles.visitInfoIcon} countryCode={row.country_code2} svg />}

                                                {getBrowserIcon(row.browser.split(" ")[0], "visit_info_modal")}


                                            </div>
                                            <div className={styles.countryLabel}>
                                                {row.country_name}
                                            </div>
                                        </div>


                                    </div>
                                    <div onClick={handleClose} className={styles.modal_close_icon_container}><IoIosClose style={{ width: "25px", height: "25px", color: "gray" }} /></div>

                                </div>

                                <div style={{ padding: "20px" }}>
                                    <div className={styles.modalFlexSpaceBetween}>
                                        <div className={styles.modalColumnLeft} style={{ color: "gray", width: "50%" }}>

                                            <div className={styles.row_container}>
                                                <p className={styles.info_data_key} >IP Address</p>
                                                <p className={styles.info_data_value}>{row.ip}</p>
                                            </div>
                                            <div className={styles.row_container}>
                                                <p className={styles.info_data_key} >Visit Counter</p>
                                                <p className={styles.info_data_value} >{row.VisitCounter}</p>
                                            </div>
                                            <div className={styles.row_container}>
                                                <p className={styles.info_data_key} >Visit Link</p>
                                                <p className={styles.info_data_value}>{row.visitlink}</p>
                                            </div>
                                            <div className={styles.row_container}>
                                                <p className={styles.info_data_key} >Avg Timespend</p>
                                                <p className={styles.info_data_value} >{getTime(row.timespend)}</p>
                                            </div>
                                            <div className={styles.row_container}>
                                                <p className={styles.info_data_key} >Last Visit</p>
                                                <p className={styles.info_data_value}>{lastVisitDetail(row.disconnectTime)}</p>
                                            </div>
                                            <div
                                                onClick={() => {
                                                    if (!row.isActiveChat && !row.isChatClosed) {
                                                        handleStartChat(row);
                                                    }
                                                }}
                                                disabled={row.isActiveChat || row.isChatClosed}
                                                className={styles.modal_start_chat}>




                                                {row.isActiveChat ? 'In Active Chat' : row.isChatClosed ? "Chat Closed" : "Start Chat"}

                                            </div>
                                        </div>
                                        <div></div>
                                        <div className={styles.modalColumnRight} style={{ width: "50%" }}>

                                            {/* <p>This is the right column. Additional content goes here.</p> */}
                                            <ActivityInfo iconContainer={"#efefef"} iconColor={"grey"} userData={[row]} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Fade>
                    </Modal>
                </TableCell>

            </TableRow>
        });
    };

    const getTime = (timeInSeconds) => {
        if (timeInSeconds <= 60) {
            return `${timeInSeconds} seconds`
        }
        // Time in seconds
        // var timeInSeconds = 96907;

        // Convert seconds to days and hours
        var days = Math.floor(timeInSeconds / (3600 * 24));
        var remainingSeconds = timeInSeconds % (3600 * 24);
        var hours = Math.floor(remainingSeconds / 3600);
        var remainingMinutes = Math.floor((remainingSeconds % 3600) / 60);
        var minutes = remainingMinutes;

        // Output the result
        if (days > 0) {
            console.log("Time in days:", days);
            return `${days} ${days > 1 ? 'days' : 'day'}`
        }
        else if (hours > 0) {
            console.log("Time in hours:", hours);
            return `${hours} ${hours > 1 ? 'hours' : 'hour'}`
        }
        else {
            return `${minutes} minutes`
        }

    }

    const getLastVisitTime = (timeString) => {
        // Time string
        // var timeString = "13:02:29 GMT-0400";

        // Extract hours, minutes, and seconds
        var timeComponents = timeString.split(":");
        var hours = parseInt(timeComponents[0]);
        var minutes = parseInt(timeComponents[1]);
        var seconds = parseInt(timeComponents[2]);

        // Convert to 12-hour format
        var period = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? hours : 12; // Handle midnight (00:00) as 12 AM

        // Format the time string
        var formattedTime = hours + ":" + (minutes < 10 ? "0" : "") + minutes + ":" + (seconds < 10 ? "0" : "") + seconds + " " + period;

        // Output the result
        // console.log("Converted time:", formattedTime);
        return formattedTime


    }

    const lastVisitDetail = (visitDetail) => {
        if (!visitDetail) return "N/A"; // Return "N/A" if visitDetail is null or undefined
        const [day, month, date, year, time] = visitDetail.split(" ");
        return `${day} ${month} ${date} ${year} ${getLastVisitTime(time)}`;
    }
    



    return (
        <TableContainer component={Paper} className={styles.table_container} style={{ maxHeight: "65vh", borderRadius: "15px" }}>
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead >
                    <TableRow>
                        <TableCell style={{ width: '18%' }} className={styles.table_heading}>Name</TableCell>
                        <TableCell style={{ width: '13%' }} onClick={() => setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc')}>
                            Entered
                            {sortOrder === 'desc' ? <FaLongArrowAltDown style={{ marginBottom: "3px", color: "#595959" }} /> : <FaLongArrowAltUp style={{ marginBottom: "3px", color: "#595959" }} />}
                        </TableCell>

                        <TableCell style={{ width: '11%' }} ><TbWorld className={styles.icon} /><img src={slash_50} alt="slash" className={styles.slash_icon} /><LaptopIcon className={styles.icon} /></TableCell>
                        <TableCell style={{ width: '20%' }} className={styles.table_heading}>Visited Page</TableCell>
                        <TableCell style={{ width: '15%' }} className={styles.table_heading}>Assigned Operator</TableCell>
                        <TableCell style={{ width: '12%' }} className={styles.table_heading}>Status</TableCell>
                        <TableCell style={{ width: '12%' }} className={styles.table_heading}></TableCell>
                        <TableCell style={{ width: '12%' }} className={styles.table_heading}></TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {renderTableContent()}
                </TableBody>
            </Table>
        </TableContainer>
    );
}